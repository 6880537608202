












































































































import { RequestPaging } from "@/utils/util";
import { Component, Vue, Watch } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import {
    apiCategoryDel,
    apiCategoryLists,
    apiCategoryStatus,
} from "@/api/goods";

@Component({
    components: {
        LsDialog,
        LsPagination,
    },
})
export default class Category extends Vue {
    $refs!: { plTreeTable: any };
    pager = new RequestPaging();

    @Watch("$route")
    routeParamsChanged(newParams: any, oldParams: any): void {
        if (newParams.path == "/goods/category") {
            this.getList();
        }
    }

    getList(): void {
        this.pager
            .request({
                callback: apiCategoryLists,
                params: { pager_type: 1 },
            })
            .then((res) => {});
    }

    handleStatus(value: number, id: number) {
        apiCategoryStatus({
            id,
            is_show: value,
        }).then(() => {
            this.getList();
        });
    }

    handleDelete(id: number) {
        apiCategoryDel(id).then(() => {
            this.getList();
        });
    }

    created() {
        this.getList();
    }
}
